import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { ColorSwatchDisplayAndEdit } from "./ColorSwatchDisplayAndEdit";
import { formatCents } from "nate-react-api-helpers";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import React, { useContext, useEffect, useState } from "react";
import { CartContext, CartItem } from "./CartProvider";
import { Product } from "../api/API";
import { FooterInput } from "./FooterInput";
import { ImperialDistance } from "./ImperialDistance";
import { Button } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useIsMobile } from "../IsMobile";
import InputLabel from "@material-ui/core/InputLabel";
import AddBoxIcon from "@material-ui/icons/AddBox";

const useStyles = makeStyles((theme) => ({
  newItem: {
    "& td:first-child": {
      borderLeft: "2px solid " + theme.palette.primary.main,
      borderBottomLeftRadius: 5,
    },
    "& td": {
      borderBottom: "2px solid " + theme.palette.primary.main,
    },
    "& td:last-child": {
      borderRight: "2px solid " + theme.palette.primary.main,
      borderBottomRightRadius: 5,
    },
  },
}));

export function CartProductRow(props: {
  index: number;
  product: Product;
  cartItem: CartItem;
  isNew?: boolean;
}) {
  const styles = useStyles();
  const cart = useContext(CartContext);
  const product = props.product;
  const index = props.index;
  const v = props.cartItem;
  const isMobile = useIsMobile();

  const [qty, setQty] = useState(props.cartItem.qty);
  const [lengthInFeet, setLengthInFeet] = useState(props.cartItem.parsedFeet);

  useEffect(() => {
    setQty(props.cartItem.qty);
  }, [props.cartItem.qty]);

  useEffect(() => {
    setLengthInFeet(props.cartItem.parsedFeet);
  }, [props.cartItem.parsedFeet]);

  const footControl = (
    <FootControl
      cartItem={props.cartItem}
      product={props.product}
      index={props.index}
      setLengthInFeet={setLengthInFeet}
    />
  );
  const qtyControl = (
    <QtyControl
      item={props.cartItem}
      index={props.index}
      qty={qty}
      setQty={setQty}
    />
  );
  const swatch = (
    <ColorSwatchDisplayAndEdit
      validColors={product.colors}
      onChange={(value) =>
        cart.update(props.index, {
          color: value,
        })
      }
      value={v.color}
    />
  );
  const rate = formatCents(product.rate);
  const total = formatCents(
    product.perFoot ? product.rate * qty * lengthInFeet : product.rate * qty
  );

  return (
    <React.Fragment>
      <TableRow
        key="first-row"
        className={props.isNew && !isMobile ? styles.newItem : undefined}
      >
        <TableCell colSpan={isMobile ? 4 : 1}>{product.name}</TableCell>
        {!isMobile && (
          <TableCell
            padding={isMobile ? "checkbox" : undefined}
            style={isMobile ? { minWidth: 105, textAlign: "right" } : undefined}
          >
            {swatch}
          </TableCell>
        )}
        {!isMobile && <TableCell>{footControl}</TableCell>}
        {!isMobile && <TableCell>{qtyControl}</TableCell>}
        {!isMobile && (
          <TableCell style={{ textAlign: "right" }}>{rate}</TableCell>
        )}
        {!isMobile && (
          <TableCell style={{ textAlign: "right" }}>{total}</TableCell>
        )}
        {!isMobile && (
          <TableCell
            padding="checkbox"
            style={{ width: props.isNew ? 155 : undefined }}
          >
            {props.isNew && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginRight: 10, width: 90 }}
                onClick={() => cart.notNew(index)}
              >
                Confirm
              </Button>
            )}
            <IconButton onClick={() => cart.remove(v)}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {isMobile && (
        <TableRow key="qty">
          <TableCell style={{ width: 30 }} />
          <TableCell>
            <InputLabel shrink={true}>Color</InputLabel>
          </TableCell>
          <TableCell padding="checkbox" style={{ minWidth: 120 }}>
            <div style={{ position: "relative", right: -5 }}>{swatch}</div>
          </TableCell>
          <TableCell />
        </TableRow>
      )}
      {isMobile && props.product.perFoot && (
        <TableRow key="length">
          <TableCell />
          <TableCell>
            <InputLabel shrink={true}>Length</InputLabel>
          </TableCell>
          <TableCell>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {footControl}
            </div>
          </TableCell>
          <TableCell />
        </TableRow>
      )}
      {isMobile && (
        <TableRow key="qty">
          <TableCell style={{ width: 30 }} />
          <TableCell>
            <InputLabel shrink={true}>Qty</InputLabel>
          </TableCell>
          <TableCell>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {qtyControl}
            </div>
          </TableCell>
          <TableCell>
            <IconButton
              color="primary"
              onClick={() => {
                const value = qty + 1;
                setQty(value);
                cart.update(index, {
                  qty: value,
                });
              }}
            >
              <AddBoxIcon fontSize="large" />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
      {isMobile && (
        <TableRow key="rate">
          <TableCell />
          <TableCell>
            <InputLabel shrink={true}>Rate</InputLabel>
          </TableCell>
          <TableCell style={{ textAlign: "right" }}>{rate}</TableCell>
          <TableCell style={{ textAlign: "right", fontWeight: "bold" }}>
            {total}
          </TableCell>
        </TableRow>
      )}
      {isMobile && (
        <TableRow key="remove">
          <TableCell />
          <TableCell>
            <button
              style={{
                textDecoration: "underline",
                background: "none",
                border: "none",
              }}
              onClick={() => cart.remove(v)}
            >
              Remove
            </button>
          </TableCell>
          <TableCell colSpan={3} style={{ textAlign: "right" }}>
            {props.isNew && (
              <Button
                onClick={() => cart.notNew(props.index)}
                variant="contained"
                color="primary"
              >
                Confirm
              </Button>
            )}
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

function QtyControl(props: {
  item: CartItem;
  index: number;
  qty: number;
  setQty(v: number): void;
}) {
  const { qty, setQty, item, index } = props;
  const cart = useContext(CartContext);

  return (
    <FooterInput
      type="number"
      initialValue={qty.toString()}
      onChange={(value) => {
        let int = parseInt(value, 10);
        if (isNaN(int) || int === 0) return;

        int = Math.abs(int);
        setQty(int);
      }}
      onBlur={(value) => {
        let int = parseInt(value, 10);
        if (isNaN(int)) return;

        if (int === 0) {
          cart.remove(item);
          return;
        }

        int = Math.abs(int);
        cart.update(index, {
          qty: int,
        });
      }}
    />
  );
}

function FootControl(props: {
  product: Product;
  index: number;
  cartItem: CartItem;
  setLengthInFeet(v: number): void;
}) {
  const { product, index, setLengthInFeet } = props;
  const cart = useContext(CartContext);

  if (!product.perFoot) return <span>-</span>;

  return (
    <FooterInput
      type="text"
      initialValue={props.cartItem.length}
      onChange={(value) => {
        try {
          const ft = Math.abs(
            Math.ceil(ImperialDistance.parse(value).inches() / 12)
          );
          if (isNaN(ft) || ft === 0) return;

          setLengthInFeet(ft);
        } catch (e) {
          // do nothing
          return;
        }
      }}
      onBlur={(value) => {
        try {
          const ft = Math.abs(
            Math.ceil(ImperialDistance.parse(value).inches() / 12)
          );
          if (isNaN(ft) || ft === 0) return;

          setLengthInFeet(props.cartItem.parsedFeet);

          cart.update(index, {
            parsedFeet: ft,
            length: value,
          });
        } catch (e) {
          // do nothing
          return;
        }
      }}
    />
  );
}
