import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PublishIcon from "@material-ui/icons/Publish";
import { useAsyncAction } from "nate-react-api-helpers";
import { api } from "../../api/API";

export function ImageEditor(props: {
  id: number;
  image: string;
  onChange(value: string): void;
}) {
  const id = props.id;

  const uploader = useAsyncAction(
    async (file: File) => {
      const result = await api.uploadImage({ id: id }, file);
      props.onChange(result.image);
    },
    [id]
  );
  const [fileRef, setFileRef] = useState<HTMLInputElement | null>();

  if (!props.id) return null;

  return (
    <Grid container>
      {props.image && (
        <Grid item>
          <IconButton
            size="small"
            onClick={() => window.open(props.image, "_blank")}
          >
            <VisibilityIcon />
          </IconButton>
        </Grid>
      )}
      <Grid item>
        <input
          accept="image/*"
          type="file"
          onChange={(e) => {
            if (!e.target.files) return;
            if (e.target.files.length === 0) return;
            uploader.callback(e.target.files[0]);
          }}
          ref={setFileRef}
          style={{ display: "none" }}
        />
        <IconButton
          size="small"
          onClick={() => {
            if (!fileRef) return;

            fileRef.click();
          }}
        >
          <PublishIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
