import React, { useState } from "react";
import { Frame } from "./Frame";
import { Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Spacer } from "./Spacer";
import { api } from "../api/API";
import {
  useAsyncAction,
  LoadableButton,
  useAuthenticated,
} from "nate-react-api-helpers";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const auth = useAuthenticated();

  const login = useAsyncAction(
    async (input: { username: string; password: string }) => {
      await api.login(input);
      auth.setAuthenticated(true);
      // @ts-ignore
      window.location = "/";
    },
    [history, auth]
  );

  return (
    <Frame>
      <Grid
        container
        style={{ minHeight: "80%" }}
        alignItems="center"
        justify="center"
      >
        <Grid item>
          <Card>
            <div style={{ padding: 16, paddingTop: 25 }}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h5" style={{ textAlign: "center" }}>
                    Contractor Login
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <TextField
                        value={email}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                        style={{ width: 300 }}
                        label="Email"
                        variant="filled"
                        name="email"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        style={{ width: 300 }}
                        label="Password"
                        variant="filled"
                        name="password"
                        type="password"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {login.error !== null && (
                  <Grid item style={{ maxWidth: 300 }}>
                    {login.LoadingElement}
                  </Grid>
                )}
                <Grid item>
                  <LoadableButton
                    loading={login.loading}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() =>
                      login.callback({
                        username: email,
                        password: password,
                      })
                    }
                  >
                    Login
                  </LoadableButton>
                </Grid>
                <Grid item style={{ textAlign: "right" }}>
                  <Button onClick={() => history.push("/forgot-password")}>
                    Forgot Password
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Card>
          <Spacer />
          <Spacer />
          <div style={{ maxWidth: 300, padding: 16 }}>
            <Typography variant="body2" color="textSecondary">
              Trouble logging in? Contact us at{" "}
              <a
                style={{ color: "inherit" }}
                href="mailto:sales@ontariometalproducts.com"
              >
                sales@ontariometalproducts.com
              </a>
            </Typography>
            <Spacer />
            <Spacer />
            <Typography variant="body2" color="textSecondary">
              Wish you could login for special pricing too? Send your request to{" "}
              <a
                style={{ color: "inherit" }}
                href="mailto:sales@ontariometalproducts.com"
              >
                sales@ontariometalproducts.com
              </a>
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Frame>
  );
}
