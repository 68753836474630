import { Card, Grid } from "@material-ui/core";
import { CategorySelector } from "./CategorySelector";
import { grey } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import { Frame } from "./Frame";
import React, { useContext, useEffect } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import green from "@material-ui/core/colors/green";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { CartContext } from "./CartProvider";
import { useIsMobile } from "../IsMobile";

export function CheckoutSuccess() {
  const history = useHistory();
  const cart = useContext(CartContext);
  useEffect(() => {
    cart.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isMobile = useIsMobile();

  return (
    <Frame forceNonMobileFooter>
      <Grid
        container
        direction="row"
        spacing={isMobile ? 0 : 4}
        style={{ width: "100%" }}
      >
        {!isMobile && (
          <Grid item>
            <CategorySelector />
          </Grid>
        )}
        <Grid item style={isMobile ? { padding: 5 } : undefined}>
          <div style={{ height: isMobile ? 0 : 20 }} />
          <Card elevation={0} style={{ border: "1px solid " + grey["200"] }}>
            <div style={{ padding: 20, paddingRight: 25 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  style={{ fontSize: 40, color: green["600"], paddingTop: 10 }}
                >
                  <CheckCircleIcon fontSize="inherit" color="inherit" />
                </Grid>
                <Grid item>
                  <Typography variant="h5">Success!</Typography>
                  <Typography variant="subtitle2">
                    Your order has been placed
                  </Typography>
                  <br />
                  {!isMobile && (
                    <Typography variant="body1">
                      We're reviewing your order and checking our scheduling.
                      You'll be hearing from us soon.
                    </Typography>
                  )}
                  {!isMobile && (
                    <Button
                      size="small"
                      style={{ marginTop: 5 }}
                      variant="outlined"
                      color="primary"
                      onClick={() => history.push("/")}
                    >
                      Continue Browsing
                    </Button>
                  )}
                </Grid>
              </Grid>
              {isMobile && (
                <Typography variant="body1">
                  We're reviewing your order and checking our scheduling. You'll
                  be hearing from us soon.
                </Typography>
              )}
              {isMobile && (
                <Button
                  size="small"
                  style={{ marginTop: 5 }}
                  variant="outlined"
                  color="primary"
                  onClick={() => history.push("/")}
                >
                  Continue Browsing
                </Button>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    </Frame>
  );
}
