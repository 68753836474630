import React from "react";
import { List } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { useLocation, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { api } from "../../api/API";
import { grey } from "@material-ui/core/colors";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import IconButton from "@material-ui/core/IconButton";

export function Nav() {
  const paths = [
    {
      link: "/admin/customers",
      name: "Customers",
    },
    {
      link: "/admin/product",
      name: "Product",
    },
    {
      link: "/admin/users",
      name: "Users",
    },
  ];

  const location = useLocation();
  const history = useHistory();

  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      style={{
        height: "100%",
        borderRight: "1px solid " + grey["300"],
        backgroundColor: "white",
      }}
    >
      <Grid item>
        <List>
          {paths.map((p) => (
            <ListItem
              key={p.name}
              button
              style={{ minWidth: 200 }}
              selected={p.link === location.pathname}
              onClick={() => history.push(p.link)}
            >
              <ListItemText primary={p.name} />
            </ListItem>
          ))}
          <ListItem
            button
            style={{ minWidth: 200 }}
            onClick={() => window.open("/", "_blank")}
          >
            <ListItemText primary="Public Site" />
            <ListItemSecondaryAction>
              <IconButton>
                <OpenInNewIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Grid>
      <Grid item>
        <ListItem
          button
          onClick={async () => {
            await api.logout();
          }}
        >
          <ListItemText primary="Logout" />
        </ListItem>
      </Grid>
    </Grid>
  );
}
