import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { api, User } from "../../api/API";
import { useAsyncAction } from "nate-react-api-helpers";
import { useSnackbar } from "notistack";
import { UserSetPassword } from "./UserSetPassword";

export function UserEditor(props: {
  user?: User;
  isNew: boolean;
  onRefresh(id?: any): void;
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const customer = props.user;
  const isNew = props.isNew;
  useEffect(() => {
    if (isNew) {
      setEmail("");
      setPassword("");
      return;
    }

    if (!customer) return;

    setEmail(customer.email);
  }, [customer, isNew]);

  const onRefresh = props.onRefresh;
  const id = props.user?.id;

  const archiver = useAsyncAction(async () => {
    await api.updateUser({
      id: id as number,
      email: email,
      archived: true,
    });

    enqueueSnackbar("User archived");
    onRefresh(undefined);
  }, [id, email, enqueueSnackbar]);

  const saver = useAsyncAction(async () => {
    if (isNew) {
      await api.createUser({
        email: email,
        password: password,
      });
      enqueueSnackbar("User created");
    } else {
      await api.updateUser({
        id: id as number,
        email: email,
      });

      enqueueSnackbar("User updated");
    }

    onRefresh(undefined);
  }, [email, id, isNew, password, onRefresh, enqueueSnackbar]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="filled"
          fullWidth
          style={{ width: 400 }}
        />
      </Grid>
      {isNew && (
        <Grid item>
          <TextField
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
            variant="filled"
            fullWidth
            style={{ width: 400 }}
          />
        </Grid>
      )}
      <Grid item />
      {saver.LoadingElement && <Grid item>{saver.LoadingElement}</Grid>}
      {archiver.LoadingElement && <Grid item>{archiver.LoadingElement}</Grid>}
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={saver.callback}
            >
              {isNew ? "Create" : "Save"}
            </Button>
          </Grid>
          {!props.isNew && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setShowPasswordModal(true)}
              >
                Set Password
              </Button>
            </Grid>
          )}
          {!props.isNew && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={archiver.callback}
              >
                Archive
              </Button>
            </Grid>
          )}
          {props.isNew && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => props.onRefresh()}
              >
                Cancel
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      {props.user !== undefined && showPasswordModal && (
        <UserSetPassword
          id={props.user.id}
          onDone={() => setShowPasswordModal(false)}
        />
      )}
    </Grid>
  );
}
