import { createContext, PropsWithChildren, useEffect, useState } from "react";
import * as React from "react";
import { api, PingResult } from "./api/API";
import { useAuthenticated } from "nate-react-api-helpers";

export const UserInfoContext = createContext<PingResult | null>(null);

export function UserInfoProvider(props: PropsWithChildren<{}>) {
  const [value, setValue] = useState<PingResult | null>(null);
  const auth = useAuthenticated();
  const isAuth = auth.authenticated;

  useEffect(() => {
    (async () => {
      try {
        const result = await api.ping();
        if (!result) {
          auth.setAuthenticated(false);
        }

        setValue(result);
      } catch (e) {}
    })();
  }, [auth, isAuth]);

  return (
    <UserInfoContext.Provider value={value}>
      {props.children}
    </UserInfoContext.Provider>
  );
}
