import React, { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import { CartEditor } from "./CartEditor";
import { useIsMobile } from "../IsMobile";

export function FooterCart(props: { onHeight(value: number): void }) {
  const [r, setR] = useState();
  const onHeight = props.onHeight;
  useEffect(() => {
    if (!r) return;

    let lastHeight = 0;

    const interval = setInterval(() => {
      const height = r.getBoundingClientRect().height;
      if (height !== lastHeight) onHeight(height);

      lastHeight = height;
    }, 500);

    return () => clearInterval(interval);
  }, [r, onHeight]);
  const isMobile = useIsMobile();

  return (
    <div
      ref={setR}
      style={{
        position: "absolute",
        bottom: isMobile ? 0 : 10,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Card elevation={2} style={isMobile ? { width: "100%" } : undefined}>
        <CartEditor title={isMobile ? "Add to Cart" : "Cart"} />
      </Card>
    </div>
  );
}
