import React, { useEffect, useState } from "react";
import { Grid, MenuItem } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { api, Customer } from "../../api/API";
import { useAsyncAction } from "nate-react-api-helpers";
import { CustomerSetPassword } from "./CustomerSetPassword";
import { useSnackbar } from "notistack";

export function CustomerEditor(props: {
  customer?: Customer;
  isNew: boolean;
  onRefresh(id?: any): void;
}) {
  const [tier, setTier] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const customer = props.customer;
  const isNew = props.isNew;
  useEffect(() => {
    if (isNew) {
      setTier("tier-1");
      setEmail("");
      setName("");
      setPassword("");
      return;
    }

    if (!customer) return;

    setTier(customer.pricingTier);
    setEmail(customer.email);
    setName(customer.name);
  }, [customer, isNew]);

  const onRefresh = props.onRefresh;
  const id = props.customer?.id;

  const archiver = useAsyncAction(async () => {
    await api.updateCustomer({
      id: id as number,
      email: email,
      name: name,
      pricingTier: tier,
      archived: true,
    });

    enqueueSnackbar("Customer archived");
    onRefresh(undefined);
  }, [id, email, tier, enqueueSnackbar]);

  const saver = useAsyncAction(async () => {
    if (isNew) {
      await api.createCustomer({
        email: email,
        name: name,
        pricingTier: tier,
        password: password,
      });
      enqueueSnackbar("Customer created");
    } else {
      await api.updateCustomer({
        id: id as number,
        email: email,
        name: name,
        pricingTier: tier,
      });

      enqueueSnackbar("Customer updated");
    }

    onRefresh(undefined);
  }, [email, name, tier, id, isNew, password, onRefresh, enqueueSnackbar]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="filled"
          fullWidth
          style={{ width: 400 }}
        />
      </Grid>
      <Grid item>
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="filled"
          fullWidth
          style={{ width: 400 }}
        />
      </Grid>
      <Grid item>
        <FormControl variant="filled" fullWidth style={{ width: 400 }}>
          <InputLabel>Pricing Tier</InputLabel>
          <Select
            value={tier}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
              setTier(e.target.value as string)
            }
          >
            <MenuItem value={"tier-1"}>Tier 1</MenuItem>
            <MenuItem value={"tier-2"}>Tier 2</MenuItem>
            <MenuItem value={"tier-3"}>Tier 3</MenuItem>
            <MenuItem value={"contractor"}>Regular Contractor</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {isNew && (
        <Grid item>
          <TextField
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
            variant="filled"
            fullWidth
            style={{ width: 400 }}
          />
        </Grid>
      )}
      <Grid item />
      {saver.LoadingElement && <Grid item>{saver.LoadingElement}</Grid>}
      {archiver.LoadingElement && <Grid item>{archiver.LoadingElement}</Grid>}
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={saver.callback}
            >
              {isNew ? "Create" : "Save"}
            </Button>
          </Grid>
          {!props.isNew && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setShowPasswordModal(true)}
              >
                Set Password
              </Button>
            </Grid>
          )}
          {!props.isNew && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={archiver.callback}
              >
                Archive
              </Button>
            </Grid>
          )}
          {props.isNew && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => props.onRefresh(props.customer?.name as any)}
              >
                Cancel
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      {props.customer !== undefined && showPasswordModal && (
        <CustomerSetPassword
          id={props.customer.id}
          onDone={() => setShowPasswordModal(false)}
        />
      )}
    </Grid>
  );
}
