import React from "react";
import { Frame } from "./Frame";
import { useAsync, ListAndDetailViewEditor } from "nate-react-api-helpers";
import { api } from "../../api/API";

import { CustomerEditor } from "./CustomerEditor";

export function Customers() {
  const customers = useAsync(() => api.getCustomers());

  return (
    <Frame>
      <ListAndDetailViewEditor
        list={customers}
        create={(props) => (
          <CustomerEditor
            isNew={true}
            onRefresh={(value) => props.onComplete(value)}
          />
        )}
        detail={(props) => (
          <CustomerEditor
            isNew={false}
            customer={props.item}
            onRefresh={props.onReload}
          />
        )}
        idExtractor={(i) => i.id}
        searchFields={(i) => [i.name, i.email]}
        displayName={(i) => i.name}
      />
    </Frame>
  );
}
