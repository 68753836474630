import { Frame } from "./Frame";
import React, { useContext, useEffect, useState } from "react";
import { Grid, Card } from "@material-ui/core";
import { CategorySelector } from "./CategorySelector";
import { grey } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import { CartEditor } from "./CartEditor";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import InputLabel from "@material-ui/core/InputLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Link, useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { CartContext } from "./CartProvider";
import { ProductContext } from "./ProductProvider";
import {
  Cents,
  first,
  formatCents,
  sum,
  useAsyncAction,
  useAuthenticated,
} from "nate-react-api-helpers";
import { api } from "../api/API";
import { UserInfoContext } from "../UserInfo";
import { useIsMobile } from "../IsMobile";

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiTableCell-root": {
      "border-bottom": "none",
    },
  },
}));

export function Checkout() {
  const styles = useStyles();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [attempted, setAttemptedSubmit] = useState(false);
  const cart = useContext(CartContext);
  const product = useContext(ProductContext);
  const userInfo = useContext(UserInfoContext);
  const auth = useAuthenticated();
  const history = useHistory();
  const isMobile = useIsMobile();

  useEffect(() => {
    setEmail(userInfo?.email || "");
    setName(userInfo?.name || "");
  }, [userInfo]);

  const subTotal = sum(
    cart.values.map((v) => {
      const p = first(product.products, (p) => p.code === v.code);
      if (!p) return 0;
      if (p.perFoot) return v.parsedFeet * v.qty * p.rate;
      return v.qty * p.rate;
    })
  );

  const taxRate = 0.13;
  const taxes = Math.round(subTotal * taxRate);
  const total = subTotal + taxes;

  const order = useAsyncAction(async () => {
    setAttemptedSubmit(true);
    if (name === "") return;
    if (phone === "" && email === "") return;

    await api.order({
      name: name,
      email: email,
      phone: phone,
      items: cart.values.map((v) => {
        let p = first(product.products, (p) => p.code === v.code) as {
          perFoot: boolean;
          rate: Cents;
        } | null;
        if (!p) p = { rate: 0, perFoot: false };

        return {
          code: v.code,
          quantity: v.qty,
          length: p.perFoot ? v.length : "",
          rate: p.rate,
          color: v.color,
          total: p.perFoot ? v.parsedFeet * v.qty * p.rate : v.qty * p.rate,
        };
      }),
      subTotal: subTotal,
      taxes: taxes,
      total: total,
    });

    history.push("/checkout-success");
  }, [name, phone, email, cart.values]);

  return (
    <Frame>
      <Grid
        container
        direction={isMobile ? "column" : "row"}
        spacing={isMobile ? 0 : 4}
        style={{ width: "100%" }}
      >
        {!isMobile && (
          <Grid item>
            <CategorySelector />
          </Grid>
        )}
        <Grid item style={isMobile ? { padding: 5, width: "100%" } : undefined}>
          <div style={{ height: isMobile ? 0 : 20 }} />
          <Card
            elevation={0}
            style={{ border: "1px solid " + grey["200"], width: "100%" }}
          >
            <div
              style={{
                padding: 20,
                paddingLeft: isMobile ? 5 : 20,
                paddingRight: isMobile ? 5 : 20,
              }}
            >
              <Typography variant="h5">Checkout</Typography>
              <div style={{ height: 10 }} />
              <Grid
                container
                alignItems={isMobile ? "center" : "stretch"}
                direction="column"
              >
                <Grid item style={{ maxWidth: "100%", width: "100%" }}>
                  <CartEditor
                    title="Product"
                    showEmpty
                    noCheckOut
                    noCollapse
                    fullHeight
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <Grid container justify="flex-end">
                      <Grid item>
                        <Table size="small" className={styles.table}>
                          <TableBody>
                            <TableRow>
                              <TableCell />
                              <TableCell>
                                <InputLabel>Sub Total</InputLabel>
                              </TableCell>
                              <TableCell />
                              <TableCell
                                style={{ width: 80, textAlign: "right" }}
                              >
                                {formatCents(subTotal)}
                              </TableCell>
                              <TableCell style={{ width: 54 }} />
                            </TableRow>
                            <TableRow>
                              <TableCell />
                              <TableCell>
                                <InputLabel>Taxes</InputLabel>
                              </TableCell>
                              <TableCell style={{ textAlign: "right" }}>
                                {taxRate * 100}%
                              </TableCell>
                              <TableCell style={{ textAlign: "right" }}>
                                {formatCents(taxes)}
                              </TableCell>
                              <TableCell />
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {!isMobile && !auth.authenticated && (
                                  <Link to="/login">
                                    <Typography
                                      color="textSecondary"
                                      variant="caption"
                                    >
                                      Are you a contractor?
                                    </Typography>
                                  </Link>
                                )}
                              </TableCell>
                              <TableCell>
                                <InputLabel>Total</InputLabel>
                              </TableCell>
                              <TableCell />
                              <TableCell style={{ textAlign: "right" }}>
                                {formatCents(total)}
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    alignItems="flex-end"
                    spacing={2}
                    direction="column"
                  >
                    <Grid item />
                    <Grid item />
                    <Grid item>
                      <TextField
                        style={{ width: 325 }}
                        variant="filled"
                        label="Name"
                        error={attempted && !name}
                        helperText={
                          attempted && !name ? "Name is required" : undefined
                        }
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        style={{ width: 325 }}
                        variant="filled"
                        label="Phone"
                        onChange={(e) => setPhone(e.target.value)}
                        error={attempted && !phone && !email}
                        helperText={
                          attempted && !phone && !email
                            ? "Email or phone is required"
                            : undefined
                        }
                        value={phone}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        style={{ width: 325 }}
                        variant="filled"
                        label="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        error={attempted && !phone && !email}
                        helperText={
                          attempted && !phone && !email
                            ? "Email or phone is required"
                            : undefined
                        }
                      />
                    </Grid>
                    <Grid item />
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>{order.LoadingElement}</Grid>
                        <Grid item>
                          <Button
                            onClick={order.callback}
                            variant="contained"
                            color="primary"
                            size="large"
                          >
                            Place Order
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Frame>
  );
}
