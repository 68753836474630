import React, { createContext, useMemo } from "react";
import { api, Product } from "../api/API";
import { useAsync } from "nate-react-api-helpers";

interface PContext {
  products: Product[];
  LoadingOrErrorElement: JSX.Element | null;
  refresh(): void;
}

export const ProductContext = createContext<PContext>({
  products: [],
  LoadingOrErrorElement: null,
  refresh: () => console.error("context not setup"),
});

export function ProductProvider(props: React.PropsWithChildren<{}>) {
  const products = useAsync(() => api.getProducts(), { withoutAuth: true });
  const ctx = useMemo(() => {
    return {
      products: products.result || [],
      LoadingOrErrorElement: products.LoadingOrErrorElement,
      refresh: products.reload,
    };
  }, [products.result, products.reload, products.LoadingOrErrorElement]);

  return (
    <ProductContext.Provider value={ctx}>
      {props.children}
    </ProductContext.Provider>
  );
}
