import { APIBase } from "nate-react-api-helpers";

class API extends APIBase {
  setCustomerPassword(input: { id: number; password: string }) {
    return this.fetcher.post("/api/customer/set-password", input);
  }

  ping() {
    return this.fetcher.get<PingResult | null>("/api/ping");
  }

  order(input: {}) {
    return this.fetcher.post("/api/checkout", input);
  }

  getUsers() {
    return this.fetcher.get<User[]>("/api/users");
  }

  createUser(input: { email: string; password: string }) {
    return this.fetcher.post("/api/user/create", input);
  }

  updateUser(input: { id: number; email: string; archived?: boolean }) {
    return this.fetcher.post("/api/user/edit", input);
  }

  setUserPassword(input: { id: number; password: string }) {
    return this.fetcher.post("/api/user/set-password", input);
  }

  getCustomers() {
    return this.fetcher.get<Customer[]>("/api/customers");
  }

  createCustomer(input: {
    email: string;
    name: string;
    pricingTier: string;
    password: string;
  }) {
    return this.fetcher.post("/api/customer/create", input);
  }

  updateCustomer(input: {
    id: number;
    email: string;
    name: string;
    pricingTier: string;
    archived?: boolean;
  }) {
    return this.fetcher.post("/api/customer/edit", input);
  }

  getProducts(category?: string) {
    return this.fetcher.get<Product[]>(
      "/api/products",
      category ? { category: category } : undefined
    );
  }

  getProductsForAdmin() {
    return this.fetcher.get<Product[]>("/api/product/list-for-admin");
  }

  updateProduct(product: Product) {
    return this.fetcher.post("/api/product/edit", product);
  }

  createProduct(product: Product) {
    return this.fetcher.post("/api/product/create", product);
  }

  login(input: { username: string; password: string }) {
    return this.fetcher.post("/api/auth/login", input);
  }

  async logout() {
    // @ts-ignore
    window.location = "/api/auth/logout";
  }

  resetPassword(input: { code: string; password: string }) {
    return this.fetcher.post("/api/auth/reset-password", input);
  }

  forgotPassword(input: { username: string }) {
    return this.fetcher.post("/api/auth/forgot-password", input);
  }

  uploadImage(input: { id: number }, file: File) {
    return this.fetcher.postFormData<Product>("/api/product/upload-image", {
      input: JSON.stringify(input),
      image: file,
    });
  }

  removeProduct(body: { id: number }) {
    return this.fetcher.post("/api/product/remove", body);
  }
}

export interface Customer {
  id: number;
  name: string;
  email: string;
  pricingTier: string;
}

export interface User {
  id: number;
  email: string;
}

export interface Product {
  id: number;
  category: string;
  name: string;
  code: string;
  colors: string[];
  callToOrder: boolean;
  perFoot: boolean;
  nonContractorRate: Cents;
  contractorRate?: Cents;
  tier1Rate?: Cents;
  tier2Rate?: Cents;
  tier3Rate?: Cents;
  rate: Cents;
  image: string;
}

type Cents = number;

export interface PingResult {
  isAdmin: boolean;
  name: string;
  email: string;
}

export const api = new API({
  jwtRefreshEndpoint: "/api/auth/refresh-token",
});
