import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableBody from "@material-ui/core/TableBody";
import Grid from "@material-ui/core/Grid";
import grey from "@material-ui/core/colors/grey";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { any, sum } from "nate-react-api-helpers";
import Button from "@material-ui/core/Button";
import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "./CartProvider";
import { ProductContext } from "./ProductProvider";
import { Product } from "../api/API";
import { CartProductRow } from "./CartProductRow";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Link } from "react-router-dom";
import { useIsMobile } from "../IsMobile";

const useStyles = makeStyles((theme) => ({
  headerCell: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "& label": {
      color: theme.palette.primary.contrastText,
      fontWeight: "bold",
    },
  },
}));

export function CartEditor(props: {
  title: string;
  noCollapse?: boolean;
  noCheckOut?: boolean;
  fullHeight?: boolean;
  showEmpty?: boolean;
}) {
  const cart = useContext(CartContext);
  const products = useContext(ProductContext);
  const [search, setSearch] = useState<Product | null>(null);
  const [collapsed, setCollapsed] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (search !== null) setSearch(null);
  }, [search]);

  if (cart.values.length === 0 && !props.showEmpty) return null;
  const hasNew = any(cart.values, (c) => !!c.isNew);
  if (isMobile && !hasNew && !props.fullHeight) return null;

  let tableBody: (JSX.Element | null)[] | null = null;
  if (!collapsed) {
    let values = cart.values;
    if (isMobile && !props.fullHeight) {
      values = values.filter((v) => !!v.isNew);
    }

    tableBody = values.map((v, index) => {
      if (!products.products) return null;
      const product = products.products.filter((p) => p.code === v.code)[0];
      if (!product) return null;

      return (
        <CartProductRow
          key={index.toString() + v.qty + v.code}
          product={product}
          index={index}
          cartItem={v}
          isNew={v.isNew}
        />
      );
    });
  }

  return (
    <React.Fragment>
      <TableContainer
        style={{
          maxHeight:
            props.fullHeight || isMobile ? undefined : window.innerHeight / 5,
          height: isMobile ? "100%" : undefined,
        }}
      >
        <Table size="small" stickyHeader>
          <TableHead>
            {isMobile ? (
              <MobileTableHeader title={props.title} />
            ) : (
              <RegularTableHeader
                title={props.title}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                noCollapse={props.noCollapse}
              />
            )}
          </TableHead>
          <TableBody>{tableBody}</TableBody>
        </Table>
      </TableContainer>
      <Grid
        container
        justify="space-between"
        style={{ borderTop: "1px solid " + grey["200"] }}
      >
        <Grid item xs>
          <div
            style={{
              padding: 10,
              paddingLeft: 16,
              maxWidth: 400,
              width: "100%",
            }}
          >
            <Autocomplete
              fullWidth
              value={search}
              onChange={(t, value) => {
                if (value) {
                  cart.add({
                    code: value.code,
                    qty: 1,
                    length: "1'",
                    parsedFeet: 1,
                    color: value.colors[0],
                  });

                  setCollapsed(false);
                }

                setSearch(value);
              }}
              options={products.products || []}
              getOptionLabel={(p) => p.name}
              filterOptions={(options, state) => {
                return options.filter(
                  (o) =>
                    o.name.indexOf(state.inputValue) !== -1 ||
                    o.code.indexOf(state.inputValue) !== -1
                );
              }}
              renderInput={(params) => {
                const { InputProps, ...inputProps } = params;

                Object.assign(InputProps, {
                  disableUnderline: true,
                });

                return (
                  <TextField
                    {...inputProps}
                    placeholder="Type to add more..."
                    InputProps={InputProps}
                  />
                );
              }}
            />
          </div>
        </Grid>
        {!props.noCheckOut && (
          <Grid item>
            <div style={{ padding: 10 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>{sum(cart.values.map((v) => v.qty))} items</Grid>
                <Grid item>
                  <Link to="/checkout">
                    <Button size="small" variant="contained" color="primary">
                      Checkout
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}

function MobileTableHeader(props: { title: string }) {
  const styles = useStyles();

  return (
    <TableRow>
      <TableCell colSpan={3} className={styles.headerCell}>
        <InputLabel shrink={true}>{props.title}</InputLabel>
      </TableCell>
      <TableCell className={styles.headerCell}>
        <InputLabel shrink={true}>Total</InputLabel>
      </TableCell>
    </TableRow>
  );
}

function RegularTableHeader(props: {
  title: string;
  noCollapse?: boolean;
  collapsed: boolean;
  setCollapsed(v: boolean): void;
}) {
  const styles = useStyles();
  const { collapsed, setCollapsed } = props;

  return (
    <TableRow>
      <TableCell className={styles.headerCell}>
        <InputLabel shrink={true}>{props.title}</InputLabel>
      </TableCell>
      <TableCell className={styles.headerCell}>
        <InputLabel shrink={true}>Color</InputLabel>
      </TableCell>
      <TableCell className={styles.headerCell}>
        <InputLabel shrink={true}>Length</InputLabel>
      </TableCell>
      <TableCell className={styles.headerCell} style={{ width: 50 }}>
        <InputLabel shrink={true}>Qty</InputLabel>
      </TableCell>
      <TableCell className={styles.headerCell}>
        <InputLabel shrink={true}>Unit Price</InputLabel>
      </TableCell>
      <TableCell className={styles.headerCell}>
        <InputLabel shrink={true}>Total</InputLabel>
      </TableCell>
      <TableCell
        style={{ textAlign: "right" }}
        className={styles.headerCell}
        padding="checkbox"
      >
        {!props.noCollapse && (
          <IconButton color="inherit" onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}
