import React, { useCallback, useEffect, useState } from "react";
import { Grid, IconButton, Button } from "@material-ui/core";
import { ColorSwatch, ColorSwatchForColor } from "../ColorSwatch";
import { Color, colors } from "../ExpandedColorPicker";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Menu from "@material-ui/core/Menu";

export function ColorEditor(props: {
  value: string[];
  onChange(values: string[]): void;
}) {
  const [selected, setSelected] = useState<Color[]>([]);
  const [showSelector, setShowSelector] = useState(false);
  const [popperRef, setPopperRef] = useState();

  const srcValue = props.value;
  useEffect(() => {
    setSelected(
      srcValue
        .map((v) => {
          return colors.filter((c) => c.code === v)[0];
        })
        .filter((s) => s !== undefined)
    );
  }, [srcValue]);

  const changeSelected = useCallback((c: Color) => {
    setSelected((old) => {
      if (old.indexOf(c) === -1) {
        return [...old, c];
      }

      return old.filter((value) => value !== c);
    });
  }, []);

  return (
    <Grid container spacing={0} wrap="nowrap" alignItems="center">
      <Grid item xs>
        <Grid container>
          {selected.map((c) => (
            <Grid item key={c.code}>
              <ColorSwatchForColor color={c} selected={false} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>
        <IconButton
          size="small"
          onClick={() => setShowSelector(true)}
          ref={setPopperRef}
        >
          <ArrowDropDownIcon />
        </IconButton>
        <Menu
          open={showSelector}
          onClose={() => {
            setShowSelector(false);
            props.onChange(selected.map((s) => s.code));
          }}
          anchorEl={popperRef}
        >
          <Grid container alignItems="center">
            <Grid item>
              <Grid container style={{ maxWidth: 200 }}>
                {colors.map((c) => (
                  <Grid item key={c.name}>
                    <ColorSwatch
                      title={c.name}
                      value={c}
                      onClick={changeSelected}
                      color={c.hex || "url(" + c.url + ")"}
                      selected={selected.indexOf(c) !== -1}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item>
              <div style={{ paddingRight: 16 }}>
                <Button onClick={() => setSelected(colors)}>All</Button>
              </div>
            </Grid>
          </Grid>
        </Menu>
      </Grid>
    </Grid>
  );
}
