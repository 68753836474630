import React, { PropsWithChildren } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  body: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
}));

export function Container(props: PropsWithChildren<{}>) {
  const styles = useStyles();
  return <div className={styles.body}>{props.children}</div>;
}
