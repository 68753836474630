import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: theme.spacing(1),
  },
}));

export function Spacer() {
  const styles = useStyles();
  return <div className={styles.spacer} />;
}
