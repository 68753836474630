import React, { useState } from "react";
import { Frame } from "./Frame";
import { Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Spacer } from "./Spacer";
import { api } from "../api/API";
import {
  useAsyncAction,
  LoadableButton,
  useAuthenticated,
} from "nate-react-api-helpers";
import { useHistory } from "react-router-dom";
import green from "@material-ui/core/colors/green";

export function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const history = useHistory();
  const auth = useAuthenticated();

  const forgotPassword = useAsyncAction(
    async (input: { username: string }) => {
      await api.forgotPassword(input);
      setSent(true);
    },
    [history, auth]
  );

  return (
    <Frame>
      <Grid
        container
        style={{ minHeight: "80%" }}
        alignItems="center"
        justify="center"
      >
        <Grid item>
          <Card>
            <div style={{ padding: 16, paddingTop: 25 }}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h5" style={{ textAlign: "center" }}>
                    Forgot Password
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <TextField
                        value={email}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                        style={{ width: 300 }}
                        label="Email"
                        variant="filled"
                        name="email"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {forgotPassword.error !== null && (
                  <Grid item style={{ maxWidth: 300 }}>
                    {forgotPassword.LoadingElement}
                  </Grid>
                )}
                <Grid item>
                  <LoadableButton
                    disabled={sent}
                    loading={forgotPassword.loading}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() =>
                      forgotPassword.callback({
                        username: email,
                      })
                    }
                  >
                    Send Reset Email
                  </LoadableButton>
                </Grid>
                {sent && (
                  <Grid item>
                    <div
                      style={{
                        backgroundColor: green["500"],
                        color: "#fff",
                        padding: 10,
                        maxWidth: 300,
                        borderRadius: 5,
                      }}
                    >
                      <Typography variant="body2">
                        An email is on it's way. You should get it within 5
                        mins. You may have to check your spam folder.
                      </Typography>
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>
          </Card>
          <Spacer />
          <Spacer />
          <div style={{ maxWidth: 300, padding: 16 }}>
            <Typography variant="body2" color="textSecondary">
              Trouble logging in? Contact us at{" "}
              <a
                style={{ color: "inherit" }}
                href="mailto:sales@ontariometalproducts.com"
              >
                sales@ontariometalproducts.com
              </a>
            </Typography>
            <Spacer />
            <Spacer />
            <Typography variant="body2" color="textSecondary">
              Wish you could login for special pricing too? Send your request to{" "}
              <a
                style={{ color: "inherit" }}
                href="mailto:sales@ontariometalproducts.com"
              >
                sales@ontariometalproducts.com
              </a>
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Frame>
  );
}
