import React, { PropsWithChildren, useContext, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Grid } from "@material-ui/core";
import { HeaderSearch } from "./HeaderSearch";
import Button from "@material-ui/core/Button";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useHistory } from "react-router-dom";
import grey from "@material-ui/core/colors/grey";
import { sum, useAuthenticated } from "nate-react-api-helpers";
import { UserInfoContext } from "../UserInfo";
import { CartContext } from "./CartProvider";
import { api } from "../api/API";
import { useIsMobile } from "../IsMobile";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 50,
  },
  header: {
    backgroundColor: "#ffffff",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    borderBottom: "1px solid " + grey["300"],
  },
  headerMobile: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: 3,
  },
  menuIcon: {
    border: "1px solid " + grey["500"],
    color: grey["800"],
    borderRadius: 5,
    padding: 8,
  },
  textCenter: {},
  link: {
    color: "inherit",
    textDecoration: "none",
  },
  popover: {
    width: "100%",
  },
}));

export function Head(
  props: PropsWithChildren<{
    onSearchChange?(value: string): void;
  }>
) {
  const styles = useStyles();
  const history = useHistory();
  const auth = useAuthenticated();
  const userInfo = useContext(UserInfoContext);
  const cart = useContext(CartContext);
  const isMobile = useIsMobile();
  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div
      className={clsx({
        [styles.header]: true,
        [styles.headerMobile]: isMobile,
      })}
    >
      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <Grid item xs={isMobile ? undefined : 3}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <IconButton
                ref={setMenuAnchorEl}
                onClick={() => setShowMenu(true)}
                className={styles.menuIcon}
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>
            </Grid>

            <Popover
              anchorEl={menuAnchorEl}
              keepMounted
              open={showMenu}
              classes={{ paper: isMobile ? styles.popover : undefined }}
              onClose={() => setShowMenu(false)}
            >
              <MenuItem
                onClick={() => {
                  setShowMenu(false);
                  history.push("/");
                }}
              >
                Products
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setShowMenu(false);
                  history.push("/checkout");
                }}
              >
                Checkout
              </MenuItem>
              <a
                className={styles.link}
                href="https://ontariometalproducts.com/contact/"
              >
                <MenuItem>Contact</MenuItem>
              </a>
              <a
                className={styles.link}
                href="https://ontariometalproducts.com/delivery/"
              >
                <MenuItem>Delivery</MenuItem>
              </a>
              <a
                className={styles.link}
                href="https://ontariometalproducts.com/gallery/"
              >
                <MenuItem>Gallery</MenuItem>
              </a>
            </Popover>

            <Grid item>
              <a
                style={isMobile ? { position: "relative", top: 3 } : undefined}
                href="https://ontariometalproducts.com/"
              >
                <img
                  src={
                    isMobile
                      ? "/logo-horizontal-mobile.png"
                      : "/logo-horizontal.png"
                  }
                  alt="logo"
                  className={styles.logo}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid item xs>
            <HeaderSearch onSearchChange={props.onSearchChange} />
          </Grid>
        )}
        <Grid
          item
          xs={isMobile ? true : 3}
          style={isMobile ? undefined : { minWidth: 310 }}
        >
          <Grid container justify="flex-end" alignItems="center" spacing={1}>
            {!auth.authenticated && (
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size={isMobile ? "small" : undefined}
                  onClick={() => history.push("/login")}
                >
                  {isMobile ? "Contractors" : "Contractor Login"}
                </Button>
              </Grid>
            )}
            {userInfo?.isAdmin && (
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => history.push("/admin")}
                >
                  Admin Panel
                </Button>
              </Grid>
            )}
            {auth.authenticated && (
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size={isMobile ? "small" : undefined}
                  onClick={() => api.logout()}
                >
                  Logout
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                size={isMobile ? "small" : undefined}
                startIcon={<ShoppingCartIcon />}
                onClick={() => history.push("/checkout")}
              >
                {sum(cart.values.map((v) => v.qty))}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
