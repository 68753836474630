import Grid from "@material-ui/core/Grid";
import { ColorSwatchForColorCode } from "./ColorSwatch";
import IconButton from "@material-ui/core/IconButton";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popover from "@material-ui/core/Popover";
import { ExpandedColorPicker } from "./ExpandedColorPicker";
import React, { useState } from "react";

export function ColorSwatchDisplayAndEdit(props: {
  validColors: string[];
  value: string;
  onChange(value: string): void;
}) {
  const [showColorSwatchPicker, setShowColorSwatchPicker] = useState(false);
  const [colorSwatchButtonRef, setColorSwatchButtonRef] = useState();

  return (
    <Grid container alignItems="center">
      <Grid item>
        <IconButton
          ref={setColorSwatchButtonRef}
          size="small"
          style={{ position: "relative", bottom: 3 }}
          onClick={() => setShowColorSwatchPicker(true)}
        >
          <ArrowDropDownIcon />
        </IconButton>
        <Popover
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          anchorEl={colorSwatchButtonRef}
          open={showColorSwatchPicker}
          onClose={() => setShowColorSwatchPicker(false)}
        >
          <ExpandedColorPicker
            value={props.value}
            validColors={props.validColors}
            hideTitle
            onChange={(value) => {
              props.onChange(value);
              setShowColorSwatchPicker(false);
            }}
          />
        </Popover>
      </Grid>
      <Grid item onClick={() => setShowColorSwatchPicker(true)}>
        <ColorSwatchForColorCode code={props.value} />
      </Grid>
    </Grid>
  );
}
