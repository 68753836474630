import React, { useContext, useState } from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { CategorySelector, useSelectedCategory } from "./CategorySelector";
import Card from "@material-ui/core/Card";
import { ProductCard } from "./ProductCard";
import { colors, ExpandedColorPicker } from "./ExpandedColorPicker";
import { Spacer } from "./Spacer";
import { Frame } from "./Frame";
import { FooterCart } from "./FooterCart";
import { ProductContext } from "./ProductProvider";
import { useIsMobile } from "../IsMobile";
import InputLabel from "@material-ui/core/InputLabel";
import grey from "@material-ui/core/colors/grey";
import { contains } from "nate-react-api-helpers";
import { WelcomeVideo } from "./WelcomeVideo";

export function Products() {
  const category = useSelectedCategory();
  const products = useContext(ProductContext);
  const [search, setSearch] = useState("");
  const [selectedColor, setSelectedColor] = useState(colors[0].code);
  const [footerHeight, setFooterHeight] = useState(100);
  const isMobile = useIsMobile();

  let visibleProducts = products.products;
  let isSearching = false;
  if (search !== "") {
    visibleProducts = visibleProducts.filter(
      (p) =>
        p.name.toLowerCase().indexOf(search) !== -1 ||
        p.code.toLowerCase() === search
    );
    isSearching = true;
  } else if (category && category !== "show all") {
    visibleProducts = visibleProducts.filter(
      (p) => p.category.toLowerCase() === category
    );
  }

  visibleProducts = visibleProducts.filter(
    (v) => v.colors.length === 0 || contains(v.colors, selectedColor)
  );

  return (
    <Frame
      aboveFooter={<FooterCart onHeight={(value) => setFooterHeight(value)} />}
      onSearchChange={(value) => setSearch(value.toLowerCase())}
    >
      <WelcomeVideo />
      <Grid
        container
        direction={isMobile ? "column" : "row"}
        spacing={isMobile ? 0 : 4}
        style={{ width: "100%", overflow: "hidden" }}
      >
        <Grid item style={isMobile ? { paddingLeft: 0 } : undefined}>
          {isMobile && (
            <div style={{ paddingLeft: 16, paddingTop: 16 }}>
              <InputLabel shrink={true}>Category</InputLabel>
            </div>
          )}
          <CategorySelector forceShowAll={isSearching} />
        </Grid>
        <Grid item xs>
          {!isMobile && <Spacer />}
          {!isMobile && <Spacer />}
          <div
            style={isMobile ? { paddingLeft: 16, paddingRight: 16 } : undefined}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid
                  container
                  alignItems="flex-end"
                  spacing={2}
                  style={{ borderBottom: "1px solid " + grey["400"] }}
                >
                  <Grid item>
                    <ExpandedColorPicker
                      onChange={setSelectedColor}
                      value={selectedColor}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction={isMobile ? "column" : "row"}
                  alignItems={isMobile ? "center" : undefined}
                  spacing={2}
                >
                  {products.LoadingOrErrorElement}
                  {products.LoadingOrErrorElement
                    ? null
                    : visibleProducts.map((p) => (
                        <Grid item key={p.id}>
                          <ProductCard
                            product={p}
                            selectedColor={selectedColor}
                          />
                        </Grid>
                      ))}
                  {products.LoadingOrErrorElement
                    ? null
                    : visibleProducts.length === 0 && (
                        <Grid item>
                          <Card elevation={0}>
                            <div style={{ padding: "16px 30px" }}>
                              <Typography variant="body1" color="textSecondary">
                                Hmm, no products for that color.
                              </Typography>
                            </div>
                          </Card>
                        </Grid>
                      )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <div style={{ height: footerHeight + 50 }} />
    </Frame>
  );
}
