import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { CssBaseline, ThemeProvider, createMuiTheme } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AuthProvider } from "nate-react-api-helpers";
import { Products } from "./components/Products";
import { Login } from "./components/Login";
import { ForgotPassword } from "./components/ForgotPassword";
import { ResetPassword } from "./components/ResetPassword";
import { UserInfoProvider } from "./UserInfo";
import { Customers } from "./components/admin/Customers";
import { SnackbarProvider } from "notistack";
import { Users } from "./components/admin/Users";
import { Product } from "./components/admin/Product";
import { CartProvider } from "./components/CartProvider";
import { Checkout } from "./components/Checkout";
import { ProductProvider } from "./components/ProductProvider";
import { CheckoutSuccess } from "./components/CheckoutSuccess";

const theme = createMuiTheme({});

function App() {
  return (
    <Router>
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <AuthProvider>
          <UserInfoProvider>
            <ProductProvider>
              <CartProvider>
                <ThemeProvider theme={theme}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <CssBaseline />
                    <Switch>
                      <Route path="/admin/product">
                        <Product />
                      </Route>
                      <Route path="/admin/customers">
                        <Customers />
                      </Route>
                      <Route path="/admin/users">
                        <Users />
                      </Route>
                      <Route path="/admin">
                        <Redirect to="/admin/customers" />
                      </Route>
                      <Route path="/login">
                        <Login />
                      </Route>
                      <Route path="/forgot-password">
                        <ForgotPassword />
                      </Route>
                      <Route path="/password-reset/:code">
                        <ResetPassword />
                      </Route>
                      <Route path="/checkout-success">
                        <CheckoutSuccess />
                      </Route>
                      <Route path="/checkout">
                        <Checkout />
                      </Route>
                      <Route path="/:category">
                        <Products />
                      </Route>
                      <Route path="/">
                        <Redirect to="/show+all" />
                      </Route>
                      <Route path="*">
                        <Redirect to="/" />
                      </Route>
                    </Switch>
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </CartProvider>
            </ProductProvider>
          </UserInfoProvider>
        </AuthProvider>
      </SnackbarProvider>
    </Router>
  );
}

export default App;
