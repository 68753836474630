import { Grid } from "@material-ui/core";
import React from "react";
import grey from "@material-ui/core/colors/grey";
import Typography from "@material-ui/core/Typography";
import { useIsMobile } from "../IsMobile";

export function Footer() {
  const isMobile = useIsMobile();
  return (
    <div
      style={{
        width: "100%",
        padding: 5,
        paddingLeft: 20,
        paddingRight: 20,
        background: grey["700"],
        color: grey["200"],
      }}
    >
      <Grid
        container
        spacing={2}
        direction={isMobile ? "column" : "row"}
        justify={isMobile ? undefined : "space-between"}
      >
        <Grid item>
          <Typography variant="body2">
            &copy; {new Date().getFullYear()}{" "}
            <a
              style={{ color: "inherit" }}
              href="https://ontariometalproducts.com/"
            >
              Ontario Metals
            </a>
          </Typography>
        </Grid>
        <Grid item>
          Questions?{" "}
          <a
            style={{ color: "inherit" }}
            href="mailto:sales@ontariometalproducts.com"
          >
            sales@ontariometalproducts.com
          </a>
          {" | "}
          <a style={{ color: "inherit" }} href="tel:9056588300">
            905.658.8300
          </a>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            Created by{" "}
            <a style={{ color: "inherit" }} href="https://blue-giraffe.ca">
              Blue Giraffe
            </a>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
