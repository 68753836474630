import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import grey from "@material-ui/core/colors/grey";
import { useIsMobile } from "../IsMobile";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: grey["200"],
    borderRadius: 3,
    border: "none",
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 20,
    width: 60,
    textAlign: "right",
  },
  mobile: {
    fontSize: 20,
  },
}));

export function FooterInput(props: {
  type: string;
  initialValue: string;
  onChange(v: string): void;
  onBlur(v: string): void;
}) {
  const [value, setValue] = useState("");
  const styles = useStyles();
  const isMobile = useIsMobile();

  useEffect(() => {
    setValue(props.initialValue);
  }, [props.initialValue]);

  return (
    <input
      className={clsx({
        [styles.input]: true,
        [styles.mobile]: isMobile,
      })}
      type={props.type}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        props.onChange(value);
      }}
      onBlur={() => props.onBlur(value)}
    />
  );
}
