import React from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { ColorSwatch } from "./ColorSwatch";
import { contains, first } from "nate-react-api-helpers";

export interface Color {
  code: string;
  name: string;
  hex?: string;
  url?: string;
}

export const colors = [
  {
    hex: "#F7F6F4",
    code: "28317",
    name: "White",
  },
  {
    hex: "#E1E1DC",
    code: "28273",
    name: "Bone White",
  },
  {
    hex: "#8F999A",
    code: "28730",
    name: "Regent Grey",
  },
  {
    hex: "#E5DABB",
    code: "28696",
    name: "Antique Linen",
  },
  {
    hex: "#9E9988",
    code: "28129",
    name: "Pebble Khak",
  },
  {
    hex: "#A1A396",
    code: "28305",
    name: "Stone Grey",
  },
  {
    hex: "#97927A",
    code: "28315",
    name: "Tan",
  },
  {
    hex: "#332722",
    code: "28326",
    name: "Coffee",
  },
  {
    hex: "#9A313A",
    code: "28386",
    name: "Bright Red",
  },
  {
    hex: "#9A313A",
    code: "28259",
    name: "Tile Red",
  },
  {
    hex: "#6D2427",
    code: "28250",
    name: "Dark Red",
  },
  {
    hex: "#441F25",
    code: "28011",
    name: "Burgundy",
  },
  {
    hex: "#0A0B09",
    code: "28262",
    name: "Black",
  },
  {
    hex: "#2A2627",
    code: "28229",
    name: "Dark Brown",
  },
  {
    hex: "#484947",
    code: "28306",
    name: "Charcoal",
  },
  {
    hex: "#203C35",
    code: "28307",
    name: "Melcher's Green",
  },
  {
    hex: "#486771",
    code: "28260",
    name: "Slate Blue",
  },
  {
    hex: "#203952",
    code: "28330",
    name: "Heron Blue",
  },
  {
    hex: "#393A33",
    code: "9837",
    name: "Green (matte)",
  },
  {
    hex: "#6E473D",
    code: "9841",
    name: "Oxide Red (matte)",
  },
  {
    hex: "#363330",
    code: "9999",
    name: "Slate Grey (matte)",
  },
  {
    hex: "#524E4A",
    code: "9821",
    name: "Graphite Grey (matte)",
  },
  {
    hex: "#39302F",
    code: "9798",
    name: "Sepia Brown (matte)",
  },
  {
    hex: "#000000",
    code: "9822",
    name: "Jet Black (matte)",
  },
  {
    url: "/colors/galvalume.png",
    code: "0002",
    name: "Galvalume",
  },
  {
    url: "/colors/galvanized.png",
    code: "0001",
    name: "Galvanized",
  },
];

export function ExpandedColorPicker(props: {
  validColors?: string[];
  hideTitle?: boolean;
  value: string;
  onChange(value: string): void;
}) {
  const selected = first(colors, (c) => c.code === props.value);
  let options = colors;

  if (props.validColors) {
    const codes = props.validColors;
    options = options.filter((o) => contains(codes, o.code));
  }

  return (
    <div>
      {!props.hideTitle && (
        <Typography
          style={{ paddingBottom: 5 }}
          variant="caption"
          color="textSecondary"
        >
          Color
        </Typography>
      )}
      <Grid container spacing={0}>
        {options.map((c) => (
          <ColorSwatch
            value={c}
            key={c.name}
            title={c.name}
            onClick={() => props.onChange(c.code)}
            color={c.hex || "url(" + c.url + ")"}
            selected={c === selected}
          />
        ))}
      </Grid>
    </div>
  );
}
