import React, { useMemo } from "react";
import { Frame } from "./Frame";
import { Typography, Card, Table, TableBody } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { ProductAddRow } from "./ProductAddRow";
import { useAsync } from "nate-react-api-helpers";
import { api } from "../../api/API";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ProductEditRow } from "./ProductEditRow";

const useStyles = makeStyles((theme) => ({
  headerRowCell: {
    whiteSpace: "nowrap",
  },
}));

export function Product() {
  const styles = useStyles();
  const products = useAsync(() => api.getProductsForAdmin());
  const categories = useMemo(() => {
    const categories: any = {
      "Sheet Metal": true,
      Trim: true,
      "Algoma Sheets": true,
      "Algoma Trim": true,
      Accessories: true,
      Screws: true,
      "Standing Seam Trim": true,
    };

    products.asList.map((p) => {
      categories[p.category] = true;
      return null;
    });

    return Object.keys(categories);
  }, [products.asList]);

  return (
    <Frame>
      <Typography variant="caption">Unlock a row to edit</Typography>
      <Card style={{ overflow: "auto" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell
                className={styles.headerRowCell}
                style={{ minWidth: 200 }}
              >
                Category
              </TableCell>
              <TableCell
                className={styles.headerRowCell}
                style={{ width: 250, minWidth: 200 }}
              >
                Name
              </TableCell>
              <TableCell
                className={styles.headerRowCell}
                style={{ minWidth: 150 }}
              >
                Code
              </TableCell>
              <TableCell
                style={{ width: 320, minWidth: 280 }}
                className={styles.headerRowCell}
              >
                Colors
              </TableCell>
              <TableCell className={styles.headerRowCell}>
                Call to Order
              </TableCell>
              <TableCell className={styles.headerRowCell}>Per Ft</TableCell>
              <TableCell className={styles.headerRowCell}>T1 Rate</TableCell>
              <TableCell className={styles.headerRowCell}>T2 Rate</TableCell>
              <TableCell className={styles.headerRowCell}>T3 Rate</TableCell>
              <TableCell className={styles.headerRowCell}>
                Contractor Rate
              </TableCell>
              <TableCell className={styles.headerRowCell}>
                Default Rate
              </TableCell>
              <TableCell
                style={{ minWidth: 100 }}
                className={styles.headerRowCell}
              >
                Image
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.LoadingOrErrorElement && (
              <TableRow>
                <TableCell colSpan={13}>
                  {products.LoadingOrErrorElement}
                </TableCell>
              </TableRow>
            )}
            {!products.loadingOrError && (
              <ProductAddRow
                key="add"
                onRefresh={products.reload}
                categories={categories}
              />
            )}
            {products.asList.map((p) => (
              <ProductEditRow
                key={p.id}
                categories={categories}
                product={p}
                onRemove={products.reload}
              />
            ))}
          </TableBody>
        </Table>
        <div style={{ height: 200 }} />
      </Card>
    </Frame>
  );
}
