import React, { useState } from "react";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import { useAsyncAction } from "nate-react-api-helpers";
import { api } from "../../api/API";
import { useSnackbar } from "notistack";

export function UserSetPassword(props: { id: number; onDone(): void }) {
  const id = props.id;
  const [password, setPassword] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const updater = useAsyncAction(async () => {
    await api.setUserPassword({
      id: id,
      password: password,
    });

    enqueueSnackbar("Password updated");
    props.onDone();
  }, [id, password, props.onDone, enqueueSnackbar]);

  return (
    <Dialog open={true}>
      <DialogTitle>Set Password</DialogTitle>
      <DialogContent>
        <TextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoFocus
          fullWidth
          style={{ width: 300 }}
          label="New Password"
        />
      </DialogContent>
      <DialogActions>
        {updater.LoadingElement}
        <Button color="primary" onClick={props.onDone}>
          Cancel
        </Button>
        <Button color="primary" onClick={updater.callback}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
