enum ParseState {
  Unknown,
  Feet,
  Inches,
  PartialInchesNumerator,
  PartialInchesDenominator,
}

export class ImperialParser {
  state: ParseState = ParseState.Unknown;
  value: string;

  feet: number = 0;
  inches: number = 0;
  partialInchNumerator: number = 0;
  partialInchDenominator: number = 0;

  feetDelimiters = ["'", "ft"];
  inchDelimiters = ['"', "in"];

  constructor(value: string) {
    this.value = value;
  }

  do(): { feet: number; inches: number } {
    let ending = this.value.length - 1;

    for (let i = this.value.length - 1; i >= 0; i--) {
      const view = this.value.substring(i, ending + 1);

      switch (this.state) {
        case ParseState.Unknown:
          if (view[0] === "/") {
            this.partialInchDenominator = parseInt(view.substring(1), 10);
            this.state = ParseState.PartialInchesNumerator;
            ending = i - 1;
            break;
          }

          const inDelim = this.inchDelimiters.findIndex(
            (delim) => view.indexOf(delim) !== -1
          );
          if (inDelim !== -1) {
            this.state = ParseState.Inches;
            ending = i - 1;
            break;
          }

          const ftDelim = this.feetDelimiters.findIndex(
            (delim) => view.indexOf(delim) !== -1
          );
          if (ftDelim !== -1) {
            const inchStr = view.substring(this.feetDelimiters[ftDelim].length);
            if (inchStr.trim() !== "") this.inches = parseFloat(inchStr);

            this.state = ParseState.Feet;
            ending = i - 1;
            break;
          }

          break;
        case ParseState.Feet:
          break;
        case ParseState.Inches:
          const ftDelimIndex = this.feetDelimiters.findIndex(
            (delim) => view.indexOf(delim) !== -1
          );
          if (ftDelimIndex !== -1) {
            const inchStr = view.substring(
              this.feetDelimiters[ftDelimIndex].length
            );
            if (inchStr.trim() !== "") this.inches = parseFloat(inchStr);

            this.state = ParseState.Feet;
            ending = i - 1;
            break;
          }

          if (this.value[i] === "/") {
            this.partialInchDenominator = parseInt(view.substring(1), 10);
            this.state = ParseState.PartialInchesNumerator;
            ending = i - 1;
            break;
          }

          break;
        case ParseState.PartialInchesNumerator:
          if (this.value[i] === " ") {
            this.partialInchNumerator = parseInt(view.substring(1), 10);
            this.state = ParseState.Inches;
            ending = i - 1;
            break;
          }
          break;
        case ParseState.PartialInchesDenominator:
          if (this.value[i] === "/") {
            this.partialInchDenominator = parseInt(view.substring(1), 10);
            this.state = ParseState.PartialInchesNumerator;
            ending = i - 1;
            break;
          }
          break;
      }
    }

    this.complete(this.value.substring(0, ending + 1));
    return this.calculate();
  }

  calculate(): { feet: number; inches: number } {
    if (this.partialInchDenominator !== 0) {
      this.inches += this.partialInchNumerator / this.partialInchDenominator;
    }

    if (isNaN(this.feet) || isNaN(this.inches)) {
      throw new Error("invalid format");
    }

    return {
      feet: this.feet,
      inches: this.inches,
    };
  }

  complete(view: string) {
    switch (this.state) {
      case ParseState.Unknown:
        this.feet = parseFloat(view);
        break;
      case ParseState.Feet:
        this.feet = parseFloat(view);
        break;
      case ParseState.Inches:
        this.inches = parseFloat(view);
        break;
      case ParseState.PartialInchesNumerator:
        this.partialInchNumerator = parseInt(view, 10);
        break;
    }
  }

  static parse(value: string): { feet: number; inches: number } {
    const parser = new ImperialParser(value);
    return parser.do();
  }
}
