import React, { useContext } from "react";
import { List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useParams, useHistory } from "react-router-dom";
import { ProductContext } from "./ProductProvider";
import { contains, distinct } from "nate-react-api-helpers";
import { useIsMobile } from "../IsMobile";

export function useSelectedCategory(): string {
  const params = useParams();
  if (!("category" in params)) return "";
  return decodeURL(params["category"]);
}

const preDefinedCategories = [
  "Sheet Metal",
  "Trim",
  "Algoma Sheets",
  "Algoma Trim",
  "Accessories",
  "Screws",
  "Standing Seam Trim",
];

export function CategorySelector(props: { forceShowAll?: boolean }) {
  const category = useSelectedCategory();
  const products = useContext(ProductContext);
  const isMobile = useIsMobile();

  let categories = distinct(products.products, (p) => p.category);
  categories = categories.filter((c) => !contains(preDefinedCategories, c));
  categories.sort();
  categories.unshift(...preDefinedCategories);

  const history = useHistory();

  return (
    <List>
      {categories.map((c, index) => (
        <ListItem
          key={c}
          button
          style={isMobile ? { width: "100%" } : { minWidth: 200 }}
          selected={
            props.forceShowAll ? index === 0 : c.toLowerCase() === category
          }
          onClick={() => history.push(encodeURL(c))}
        >
          <ListItemText primary={c} />
        </ListItem>
      ))}
    </List>
  );
}

function encodeURL(str: string): string {
  return encodeURIComponent(str.toLowerCase()).replace(/%20/g, "+");
}

function decodeURL(str: string): string {
  return decodeURIComponent(str.replace(/\+/g, "%20"));
}
