import React, { useEffect, useState } from "react";
import { TextField, OutlinedInput } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Autocomplete } from "@material-ui/lab";
import { useHistory, useLocation } from "react-router-dom";
import { useSelectedCategory } from "./CategorySelector";

export function HeaderSearch(props: {
  onSearchChange?: (value: string) => void;
}) {
  const [value, setValue] = useState("");
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search).get("search");
  const category = useSelectedCategory();

  useEffect(() => {
    if (searchParams) {
      setValue(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    if (category !== "show all") setValue("");
  }, [category]);

  useEffect(() => {
    props.onSearchChange?.(value);
  }, [value, props.onSearchChange]);

  if (!props.onSearchChange) {
    return (
      <Autocomplete
        options={[] as { text: string; value: string }[]}
        getOptionLabel={(opt) => opt.text}
        filterOptions={(options, state) => {
          if (state.inputValue !== "")
            return [
              {
                text: "View Products Matching: " + state.inputValue,
                value: state.inputValue,
              },
            ];
          return [];
        }}
        freeSolo
        onChange={(e, value: any) => {
          if (!value) return;
          if (value instanceof Array) return;
          if (typeof value === "string") {
            history.push("/show+all?search=" + value);
            return;
          }

          history.push("/show+all?search=" + value.value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search"
            margin="normal"
            variant="outlined"
          />
        )}
      />
    );
  }

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="searcher" style={{ marginLeft: 16, marginTop: -2 }}>
        Search
      </InputLabel>
      <OutlinedInput
        id="searcher"
        label="Search"
        name="search"
        fullWidth
        onChange={(e) => setValue(e.target.value)}
        value={value}
        endAdornment={
          value !== "" && (
            <InputAdornment position="end">
              <IconButton onClick={() => setValue("")} edge="end">
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          )
        }
      />
    </FormControl>
  );
}
