import React, { useCallback, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CheckIcon from "@material-ui/icons/Check";
import { getLuminance, Grid } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import IconButton from "@material-ui/core/IconButton";
import blue from "@material-ui/core/colors/blue";
import Tooltip from "@material-ui/core/Tooltip";
import { Color, colors } from "./ExpandedColorPicker";
import { first } from "nate-react-api-helpers";
import { useIsMobile } from "../IsMobile";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  swatch: {
    width: 20,
    height: 20,
    borderRadius: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    border: "1px solid " + grey["300"],
  },
  swatchLarge: {
    width: 40,
    height: 40,
  },
  selected: {
    border: "2px solid " + blue["300"],
  },
}));

export function ColorSwatchForColorCode(props: { code: string }) {
  const c = first(colors, (c) => c.code === props.code);
  if (!c) return null;
  return ColorSwatchForColor({ color: c, selected: false });
}

export function ColorSwatchForColor(props: {
  color: Color;
  selected: boolean;
}) {
  const c = props.color;
  return (
    <ColorSwatch
      key={c.name}
      title={c.name}
      color={c.hex || "url(" + c.url + ")"}
      value={c}
      selected={props.selected}
    />
  );
}

export function ColorSwatch(props: {
  title: string;
  color: string;
  selected: boolean;
  value: Color;
  onClick?(value: Color): void;
}) {
  const style = useStyles();
  const isMobile = useIsMobile();

  const { onClick, value } = props;
  const click = useCallback(() => {
    if (!onClick) return;
    onClick(value);
  }, [onClick, value]);

  const [gridRef, setGridRef] = useState();

  return (
    <Grid item ref={setGridRef}>
      {gridRef && (
        <Tooltip title={props.title}>
          <div>
            <IconButton size="small" disabled={!props.onClick} onClick={click}>
              <div
                className={clsx({
                  [style.swatch]: true,
                  [style.selected]: props.selected,
                  [style.swatchLarge]: isMobile,
                })}
                style={{ background: props.color }}
              >
                {props.selected && (
                  <CheckIcon
                    style={{
                      color: isNearlyWhite(props.color) ? grey["800"] : "white",
                      fontSize: 18,
                    }}
                  />
                )}
              </div>
            </IconButton>
          </div>
        </Tooltip>
      )}
    </Grid>
  );
}

function isNearlyWhite(color: string) {
  if (!color.startsWith("#")) return false;
  return getLuminance(color) > 0.6;
}
