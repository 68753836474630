import { ImperialParser } from "./ImperialParser";
type UniversalUnits = number;

export class ImperialDistance {
  sixteenthOfInch: number;

  constructor(sixteenthOfInch: number) {
    this.sixteenthOfInch = Math.round(sixteenthOfInch);
  }

  simplifySixteenths(n: number): string {
    switch (Math.abs(n)) {
      case 0:
        return "";
      case 1:
        return "1/16";
      case 2:
        return "1/8";
      case 3:
        return "3/16";
      case 4:
        return "1/4";
      case 5:
        return "5/16";
      case 6:
        return "3/8";
      case 7:
        return "7/16";
      case 8:
        return "1/2";
      case 9:
        return "9/16";
      case 10:
        return "5/8";
      case 11:
        return "11/16";
      case 12:
        return "3/4";
      case 13:
        return "13/16";
      case 14:
        return "7/8";
      case 15:
        return "15/16";
      default:
        console.error("invalid sixteenth", n);
        return "";
    }
  }

  toString(): string {
    let parts = [];

    let totalInches = Math.floor(this.sixteenthOfInch / 16);
    const sixteenths = this.sixteenthOfInch - totalInches * 16;
    const sixteenthStr = this.simplifySixteenths(sixteenths);

    const inches = totalInches % 12;
    if (inches !== 0) {
      if (sixteenthStr === "") {
        parts.push(inches + '"');
      } else {
        parts.push(inches + " " + sixteenthStr + '"');
      }
    } else {
      if (sixteenthStr !== "") {
        parts.push(sixteenthStr + '"');
      }
    }

    totalInches -= inches;
    const ft = Math.floor(totalInches / 12);
    if (ft !== 0) {
      parts.push(ft + "'");
    }

    return parts.reverse().join(" ");
  }

  universalValue(): number {
    return this.sixteenthOfInch;
  }

  static fromUniversal(n: number) {
    return new ImperialDistance(n || 0);
  }

  inches() {
    return Math.round(this.sixteenthOfInch / 16);
  }

  rounded(): ImperialDistance {
    return new ImperialDistance(Math.round(this.sixteenthOfInch / 16) * 16);
  }

  getValue(): number {
    return this.sixteenthOfInch;
  }

  static fromFeetAndInches(input: { feet: number; inches: number }) {
    return new ImperialDistance(input.feet * 12 * 16 + input.inches * 16);
  }

  static parse(value: string): ImperialDistance {
    const parsed = ImperialParser.parse(value);
    return ImperialDistance.fromFeetAndInches(parsed);
  }

  multiplyBy(number: number) {
    return new ImperialDistance(this.sixteenthOfInch * number);
  }

  cloneWithValue(value: UniversalUnits): ImperialDistance {
    return ImperialDistance.fromUniversal(value);
  }

  static show(units: UniversalUnits): string {
    return ImperialDistance.fromUniversal(units).toString();
  }

  static showInches(units: UniversalUnits): string {
    return ImperialDistance.fromUniversal(units).inches() + '"';
  }
}
