import React, { useState } from "react";
import { Frame } from "./Frame";
import { Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Spacer } from "./Spacer";
import { api } from "../api/API";
import { useAsyncAction, LoadableButton } from "nate-react-api-helpers";
import { Link, useParams } from "react-router-dom";

export function ResetPassword() {
  const [password, setPassword] = useState("");
  const params = useParams();
  const code = "code" in params ? params["code"] : "";
  const [done, setDone] = useState(false);

  const reset = useAsyncAction(
    async (input: { password: string }) => {
      await api.resetPassword({ password: input.password, code: code });
      setDone(true);
    },
    [code]
  );

  return (
    <Frame>
      <Grid
        container
        style={{ minHeight: "80%" }}
        alignItems="center"
        justify="center"
      >
        <Grid item>
          <Card>
            <div style={{ padding: 16, paddingTop: 25 }}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h5" style={{ textAlign: "center" }}>
                    Reset Password
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <input
                        type="text"
                        style={{
                          width: 0,
                          height: 0,
                          position: "absolute",
                          zIndex: -1,
                        }}
                        name="Email"
                      />
                      <TextField
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        style={{ width: 300 }}
                        label="New Password"
                        variant="filled"
                        name="password"
                        type="password"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {reset.error !== null && (
                  <Grid item style={{ maxWidth: 300 }}>
                    {reset.LoadingElement}
                  </Grid>
                )}
                <Grid item>
                  <LoadableButton
                    loading={reset.loading}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() =>
                      reset.callback({
                        password: password,
                      })
                    }
                  >
                    Reset Password
                  </LoadableButton>
                </Grid>
                {done && (
                  <Grid item>
                    <Typography style={{ maxWidth: 300 }}>
                      Your password has been updated.
                      <br />
                      Let's <Link to="/login">Login</Link>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </div>
          </Card>
          <Spacer />
          <Spacer />
          <div style={{ maxWidth: 300, padding: 16 }}>
            <Typography variant="body2" color="textSecondary">
              Trouble logging in? Contact us at{" "}
              <a
                style={{ color: "inherit" }}
                href="mailto:sales@ontariometalproducts.com"
              >
                sales@ontariometalproducts.com
              </a>
            </Typography>
            <Spacer />
            <Spacer />
            <Typography variant="body2" color="textSecondary">
              Wish you could login for special pricing too? Send your request to{" "}
              <a
                style={{ color: "inherit" }}
                href="mailto:sales@ontariometalproducts.com"
              >
                sales@ontariometalproducts.com
              </a>
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Frame>
  );
}
