import { Product } from "../api/API";
import React, { useContext, useState } from "react";
import { Card, Grid, Link } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import Button from "@material-ui/core/Button";
import { CartContext } from "./CartProvider";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 265,
    height: 182,
    display: "flex",
    flexDirection: "column",
    border: "1px solid transparent",
    cursor: "pointer",
  },
  cardInactive: {
    border: "1px solid " + grey["200"],
    "& .product-title": {
      opacity: 0.5,
    },
  },
  cardActive: {
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
    },
    "&:hover .cart-button": {
      display: "blocK",
    },
  },
  image: {
    position: "relative",
    flex: 1,
    backgroundColor: "#dbdbdb",
    backgroundPosition: "center center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    borderBottom: "1px solid " + grey["200"],
  },
  title: {
    backgroundColor: "white",
    padding: theme.spacing(1),
  },
  overlay: {
    backgroundColor: grey["200"],
  },
  addToCart: {
    display: "none",
    position: "absolute",
    top: 0,
    right: 0,
    padding: theme.spacing(1),
  },
}));

export function ProductCard(props: {
  product: Product;
  selectedColor: string;
}) {
  const styles = useStyles();
  const cart = useContext(CartContext);
  const [cardRef, setCardRef] = useState();

  let imageContent = null;

  if (props.product.callToOrder) {
    imageContent = (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ height: "100%" }}
        className={styles.overlay}
      >
        <Grid item>
          <Typography
            color="textSecondary"
            style={{
              fontSize: 18,
              padding: 20,
              paddingBottom: 5,
              textAlign: "center",
            }}
          >
            NOT AVAILABLE ONLINE
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Contact
          </Typography>
          <Link href="mailto:bruce@ontariometalproducts.com">
            <Typography color="textSecondary" variant="body2">
              bruce@ontariometalproducts.com
            </Typography>
          </Link>
          <Link href="mailto:sales@ontariometalproducts.com">
            <Typography color="textSecondary" variant="body2">
              905.658.8300
            </Typography>
          </Link>
        </Grid>
      </Grid>
    );
  }

  const notAvailable = imageContent !== null;

  return (
    <Card
      ref={setCardRef}
      className={
        styles.card +
        " " +
        (notAvailable ? styles.cardInactive : styles.cardActive)
      }
      elevation={notAvailable ? 0 : 1}
      onClick={() => {
        if (notAvailable) return;
        cart.add({
          code: props.product.code,
          color: props.selectedColor,
          length: "1'",
          parsedFeet: 1,
          qty: 1,
          isNew: true,
        });
      }}
    >
      <div
        className={styles.image}
        style={
          props.product.image
            ? {
                backgroundImage: "url('" + props.product.image + "'",
                backgroundColor: "white",
              }
            : {}
        }
      >
        {imageContent}
        {cardRef && (
          <div className={styles.addToCart + " cart-button"}>
            <Button
              size="small"
              color="primary"
              variant="contained"
              startIcon={<AddShoppingCartIcon />}
            >
              Add to Cart
            </Button>
          </div>
        )}
      </div>
      <div className={styles.title + " product-title"}>
        <Typography variant="subtitle2">{props.product.name}</Typography>
      </div>
    </Card>
  );
}
