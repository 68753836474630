import * as React from "react";
import { Dialog, Popper, Slide } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { TransitionProps } from "@material-ui/core/transitions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { grey } from "@material-ui/core/colors";
import { useEffect, useState } from "react";
import Cookie from "js-cookie";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 10,
    background: "white",
    width: "40px",
    height: "40px",
    fontSize: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: grey["300"],
    },
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const cookieName = "welcome-video";

export function WelcomeVideo() {
  const [show, setShow] = useState(() => {
    return Cookie.get(cookieName) === undefined;
  });
  const setResizeRender = useState(false)[1];

  useEffect(() => {
    const callback = () => {
      setResizeRender((old) => !old);
    };

    window.addEventListener("resize", callback);
    return () => {
      window.removeEventListener("resize", callback);
    };
  }, [setResizeRender]);

  if (!show) return null;

  let width = "1008";
  let height = "567";
  let whiteModal = false;

  if (window.innerWidth < 1020) {
    width = "640";
    height = "360";
  }

  if (window.innerWidth < 740) {
    width = window.innerWidth.toString();
    height = Math.min(
      window.innerHeight,
      (window.innerWidth / 1008) * 567
    ).toString();
    whiteModal = true;
  }

  const contents = (
    <Contents
      width={width}
      height={height}
      onClose={() => {
        setShow(false);
        Cookie.set(cookieName, "shown", {
          expires: new Date(
            new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000
          ),
          path: "/",
        });
      }}
    />
  );

  if (whiteModal) {
    return (
      <Popper open={show}>
        <div
          style={{
            width: window.innerWidth,
            height: window.innerHeight,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            backgroundColor: "white",
          }}
        >
          {contents}
        </div>
      </Popper>
    );
  }

  return (
    <Dialog
      open={show}
      maxWidth="lg"
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          overflow: "auto",
        },
      }}
    >
      {contents}
    </Dialog>
  );
}

function Contents(props: { width: string; height: string; onClose(): void }) {
  const styles = useStyles();

  return (
    <>
      <div className={styles.closeButton} onClick={props.onClose}>
        <CloseIcon fontSize="inherit" />
      </div>
      <iframe
        width={props.width}
        height={props.height}
        src="https://www.youtube.com/embed/fHmFtBQRY-E?autoplay=1&mute=1"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </>
  );
}
