import { Head } from "./Head";
import { Body } from "./Body";
import { Container } from "./Container";
import React, { PropsWithChildren } from "react";
import { Footer } from "./Footer";
import { useIsMobile } from "../IsMobile";

export function Frame(
  props: PropsWithChildren<{
    aboveFooter?: JSX.Element;
    onSearchChange?(value: string): void;
    bodyBottomPadding?: number;
    forceNonMobileFooter?: boolean;
  }>
) {
  const isMobile = useIsMobile();
  const mobileFooter = isMobile && !props.forceNonMobileFooter;

  return (
    <Container>
      <Head onSearchChange={props.onSearchChange} />
      <Body>
        {props.children}
        {mobileFooter && <Footer />}
      </Body>
      <div style={{ position: "relative", width: "100%" }}>
        {props.aboveFooter}
      </div>
      {!mobileFooter && <Footer />}
    </Container>
  );
}
