import { useAsyncAction } from "nate-react-api-helpers";
import { api, Product } from "../../api/API";
import React, { useState } from "react";
import { ProductRow } from "./ProductAddRow";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSnackbar } from "notistack";

const timeout = 3000;
export function ProductEditRow(props: {
  product: Product;
  categories: string[];
  onRemove(product: Product): void;
}) {
  const { product } = props;
  const [lastSavedAt, setLastSavedAt] = useState();
  const [, setRefresh] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const editor = useAsyncAction(
    async (p: Product) => {
      await api.updateProduct(p);
      setLastSavedAt(new Date().getTime());
      enqueueSnackbar("Saved");

      setTimeout(() => {
        setRefresh((i) => i + 1);
      }, timeout + 10);
    },
    [enqueueSnackbar]
  );

  const remove = useAsyncAction(async () => {
    await api.removeProduct({ id: props.product.id });
    props.onRemove(props.product);
    enqueueSnackbar("Removed Product");
  }, [props.product, enqueueSnackbar]);

  const showSaved = new Date().getTime() - lastSavedAt < timeout;

  return (
    <ProductRow
      product={product}
      onChange={editor.callback}
      categories={props.categories}
      lastCell={
        editor.LoadingElement ||
        remove.LoadingElement ||
        (showSaved && <Typography variant="caption">Saved</Typography>) || (
          <IconButton onClick={remove.callback}>
            <DeleteIcon />
          </IconButton>
        )
      }
    />
  );
}
