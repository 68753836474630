import React, { PropsWithChildren, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Nav } from "./Nav";
import { Body } from "../Body";
import { Container } from "../Container";
import { useAuthenticated } from "nate-react-api-helpers";
import Typography from "@material-ui/core/Typography";

export function Frame(props: PropsWithChildren<{}>) {
  const auth = useAuthenticated();

  useEffect(() => {
    if (!auth.authenticated) {
      // @ts-ignore
      window.location = "/login";
    }
  }, [auth.authenticated]);

  return (
    <Container>
      <div
        style={{
          backgroundColor: "#457b9d",
          color: "#fff",
          padding: "10px 15px",
        }}
      >
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Internal Control Panel
        </Typography>
      </div>
      <Body>
        <Grid
          container
          spacing={2}
          style={{
            height: "100%",
            width: "100%",
            marginBottom: 0,
            marginTop: 0,
          }}
        >
          <Grid item style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Nav />
          </Grid>
          <Grid item xs style={{ overflow: "auto" }}>
            <div style={{ height: 10 }} />
            {props.children}
          </Grid>
        </Grid>
      </Body>
    </Container>
  );
}
