import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { api, Product } from "../../api/API";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Checkbox } from "@material-ui/core";
import {
  formatCents,
  LoadableButton,
  parseCents,
  useAsyncAction,
} from "nate-react-api-helpers";
import { ColorEditor } from "./ColorEditor";
import { ImageEditor } from "./ImageEditor";
import { useSnackbar } from "notistack";
import makeStyles from "@material-ui/core/styles/makeStyles";

const newProduct = {
  id: 0,
  category: "",
  name: "",
  colors: [],
  code: "",
  callToOrder: false,
  perFoot: false,
  nonContractorRate: 0,
  rate: 0,
  image: "",
};

export function ProductAddRow(props: {
  onRefresh(): void;
  categories: string[];
}) {
  const { onRefresh } = props;
  const { enqueueSnackbar } = useSnackbar();
  const creator = useAsyncAction(
    async (p: Product) => {
      await api.createProduct(p);
      onRefresh();
      enqueueSnackbar("Added Product");
    },
    [onRefresh, enqueueSnackbar]
  );
  const [value, setValue] = useState<Product>(newProduct);

  return (
    <ProductRow
      product={newProduct}
      onChange={setValue}
      categories={props.categories}
      isInsert
      lastCell={
        <LoadableButton
          loading={creator.loading}
          size="small"
          color="primary"
          variant="contained"
          onClick={() => creator.callback(value)}
          disabled={
            value.category === "" || value.code === "" || value.name === ""
          }
        >
          Add
        </LoadableButton>
      }
    />
  );
}

const useStyles = makeStyles((theme) => ({
  editButton: {
    padding: "3px 10px",
    backgroundColor: "#fff",
    cursor: "pointer",
    borderRadius: 5,
    color: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
    textAlign: "center",

    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: "#fff",
    },
  },
  editCell: {
    display: "flex",
    flexDirection: "row",
    width: 110,
    alignItems: "center",
  },
}));

export function ProductRow(props: {
  product: Product;
  isInsert?: boolean;
  onChange(value: Product): void;
  lastCell: JSX.Element | null;
  categories: string[];
}) {
  const styles = useStyles();

  const [product, setProduct] = useState<Product & { isEdited?: boolean }>(
    props.product
  );
  const [category, setCategory] = useState<string | null>(
    props.product.category
  );
  const [perFt, setPerFt] = useState(props.product.perFoot);
  const [callToOrder, setCallToOrder] = useState(props.product.callToOrder);

  const onChange = props.onChange;

  useEffect(() => {
    setProduct(props.product);
    setCategory(props.product.category);
    setPerFt(props.product.perFoot);
    setCallToOrder(props.product.callToOrder);
  }, [props.product]);

  useEffect(() => {
    if (!product.isEdited) return;

    setProduct(
      Object.assign({}, product, {
        isEdited: false,
      })
    );

    onChange(product);
  }, [product, onChange]);

  const saveDollars = useCallback((key: keyof Product, value?: string) => {
    setProduct((p) =>
      Object.assign({}, p, {
        [key]: value !== undefined ? parseInt(value, 10) : undefined,
        isEdited: true,
      })
    );
  }, []);

  const save = useCallback((key: keyof Product, value?: string | string[]) => {
    setProduct((p) =>
      Object.assign({}, p, {
        [key]: value,
        isEdited: true,
      })
    );
  }, []);

  const [editMode, setEditMode] = useState(props.isInsert || false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (editMode) setSearch("");
  }, [editMode]);

  const categories = useMemo(() => {
    if (!search) return props.categories;
    if (props.categories.indexOf(search) !== -1) {
      return props.categories;
    }

    return [search, ...props.categories];
  }, [search, props.categories]);

  if (!editMode) {
    return (
      <TableRow>
        <TableCell>
          <div onClick={() => setEditMode(true)} className={styles.editButton}>
            Edit
          </div>
        </TableCell>
        <TableCell>{category}</TableCell>
        <TableCell>{product.name}</TableCell>
        <TableCell>{product.code}</TableCell>
        <TableCell>{product.colors.length} colors</TableCell>
        <TableCell>{product.callToOrder ? "Yes" : "No"}</TableCell>
        <TableCell>{product.perFoot ? "Yes" : "No"}</TableCell>
        <TableCell>{displayDollars(product.tier1Rate)}</TableCell>
        <TableCell>{displayDollars(product.tier2Rate)}</TableCell>
        <TableCell>{displayDollars(product.tier3Rate)}</TableCell>
        <TableCell>{displayDollars(product.contractorRate)}</TableCell>
        <TableCell>{displayDollars(product.nonContractorRate)}</TableCell>
        <TableCell>{!product.image ? "None" : "Yes"}</TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell>
        <div className={styles.editCell}>
          {!props.isInsert && (
            <div
              onClick={() => {
                setEditMode(false);
              }}
              className={styles.editButton}
            >
              Done
            </div>
          )}
          {props.lastCell}
        </div>
      </TableCell>
      <TableCell padding="checkbox">
        <Autocomplete
          options={categories}
          getOptionLabel={(option) => option}
          value={category}
          freeSolo
          onChange={(t, value) => {
            setCategory(value);

            if (value) {
              setProduct(
                Object.assign({}, product, {
                  category: value,
                  isEdited: true,
                })
              );
            }
          }}
          fullWidth
          renderInput={(params) => {
            const { InputProps, ...inputProps } = params;

            Object.assign(InputProps, {
              disableUnderline: true,
            });

            return (
              <TextField
                {...inputProps}
                onChange={(e) => {
                  setSearch(e.target.value);

                  // @ts-ignore
                  if (inputProps["onChange"]) {
                    // @ts-ignore
                    inputProps["onChange"](e);
                  }
                }}
                placeholder="Type to add more..."
                InputProps={InputProps}
              />
            );
          }}
        />
      </TableCell>
      <Field
        value={product.name}
        required
        onChange={(value) => save("name", value)}
      />
      <Field
        value={product.code}
        required
        onChange={(value) => save("code", value)}
      />
      <TableCell padding="checkbox">
        <ColorEditor
          value={product.colors}
          onChange={(value) => {
            console.log("onchange", "colors", value);
            save("colors", value);
          }}
        />
      </TableCell>
      <TableCell padding="checkbox">
        <Checkbox
          checked={callToOrder}
          onChange={(e) => {
            const value = e.target.checked;
            setCallToOrder(value);
            setProduct(
              Object.assign({}, product, {
                callToOrder: value,
                isEdited: true,
              })
            );
          }}
          color="primary"
        />
      </TableCell>
      <TableCell padding="checkbox">
        <Checkbox
          checked={perFt}
          onChange={(e) => {
            const value = e.target.checked;
            setPerFt(value);
            setProduct(
              Object.assign({}, product, {
                perFoot: value,
                isEdited: true,
              })
            );
          }}
          color="primary"
        />
      </TableCell>
      <Field
        dollars
        onChange={(value) => saveDollars("tier1Rate", value)}
        value={product.tier1Rate}
      />
      <Field
        dollars
        onChange={(value) => saveDollars("tier2Rate", value)}
        value={product.tier2Rate}
      />
      <Field
        dollars
        onChange={(value) => saveDollars("tier3Rate", value)}
        value={product.tier3Rate}
      />
      <Field
        dollars
        onChange={(value) => saveDollars("contractorRate", value)}
        value={product.contractorRate}
      />
      <Field
        dollars
        onChange={(value) => saveDollars("nonContractorRate", value)}
        value={formatCents(product.nonContractorRate)}
        required
      />
      <TableCell padding="checkbox">
        <ImageEditor
          id={product.id}
          image={product.image}
          onChange={(value) => save("image", value)}
        />
      </TableCell>
    </TableRow>
  );
}

export function displayDollars(value: any) {
  if (typeof value === "number") {
    return formatCents(value);
  } else if (value === "string") {
    return formatCents(parseInt(value));
  }

  return "";
}

function Field(props: {
  value: string | number | undefined;
  dollars?: boolean;
  required?: boolean;
  onChange(value?: string): void;
}) {
  const [warning, setWarning] = useState();
  const [value, setValue] = useState("");

  const propsDollars = props.dollars;
  const propsValue = props.value;

  useEffect(() => {
    if (propsDollars) {
      setValue(displayDollars(propsValue));
    }

    setValue((propsValue || "").toString());
  }, [propsValue, propsDollars]);

  return (
    <TableCell padding="checkbox">
      <TextField
        value={value}
        onBlur={(e) => {
          let newValue = e.target.value;
          if (props.dollars) {
            const cents = parseCents(newValue);
            newValue = cents !== 0 ? formatCents(cents) : "";
            setValue(newValue);

            if (props.required && cents === 0) {
              setWarning("required");
            }

            if (cents !== props.value) {
              if (props.value === null && cents === 0) return;
              props.onChange(cents !== 0 ? cents.toString() : undefined);
            }
            return;
          }

          if (props.required && value === "") {
            setWarning("required");
          }

          if (newValue !== props.value) {
            props.onChange(value);
          }
        }}
        onChange={(e) => {
          if (e.target.value > "" && warning) setWarning(undefined);
          setValue(e.target.value);
        }}
        placeholder={props.dollars && !props.required ? "N/A" : ""}
        InputProps={{ disableUnderline: true }}
        fullWidth
        helperText={warning}
        error={!!warning}
      />
    </TableCell>
  );
}
