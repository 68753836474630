import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import { first } from "nate-react-api-helpers";

export interface CartItem {
  code: string;
  color: string;
  length: string;
  parsedFeet: number;
  qty: number;
  isNew?: boolean;
}

interface Context {
  values: CartItem[];
  clear(): void;
  add(item: CartItem): void;
  remove(item: CartItem): void;
  update(index: number, update: Partial<CartItem>): void;
  notNew(index: number): void;
}

export const CartContext = createContext<Context>({
  values: [],
  clear: () => console.error("invalid context"),
  add: () => console.error("invalid context"),
  remove: () => console.error("invalid context"),
  update: () => console.error("invalid context"),
  notNew: () => console.error("invalid context"),
});

export function CartProvider(props: PropsWithChildren<{}>) {
  const [cart, setCart] = useState<CartItem[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const cart = localStorage.getItem("cart");
    if (!cart) {
      setLoaded(true);
      return;
    }

    const items = JSON.parse(cart) as CartItem[];
    for (let item of items) {
      item.isNew = undefined;
    }

    setCart(items);
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [loaded, cart]);

  const value = useMemo(() => {
    return {
      values: cart,
      add: (value: CartItem) =>
        setCart((c) => {
          const newItem = first(c, (v) => !!v.isNew);

          // add to existing item
          if (
            newItem &&
            newItem.code === value.code &&
            newItem.color === value.color
          ) {
            const item = Object.assign({}, newItem, {
              qty: newItem.qty + value.qty,
            });

            return c.map((v) => (v === newItem ? item : v));
          }

          return [
            value,
            ...c.map((c) => {
              c.isNew = undefined;
              return c;
            }),
          ];
        }),
      remove: (value: CartItem) => setCart((c) => c.filter((v) => v !== value)),
      clear: () => setCart([]),
      notNew: (index: number) =>
        setCart((values) =>
          values.map((v, i) => {
            if (i === index) v.isNew = undefined;
            return v;
          })
        ),
      update: (index: number, value: Partial<CartItem>) => {
        setCart((c) =>
          c.map((row, i) => {
            if (index === i) return Object.assign({}, row, value);
            return row;
          })
        );
      },
    };
  }, [cart]);

  return (
    <CartContext.Provider value={value}>{props.children}</CartContext.Provider>
  );
}
